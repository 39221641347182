import { invokeApi } from "../../bl_libs/invokeApi";

export const get_leagues_list_api = async () => {
  const requestObj = {
    path: `api/league/get_leagues`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_league_api = async (data) => {
  const requestObj = {
    path: `api/league/create`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_league_api = async (league_id) => {
  const requestObj = {
    path: `api/league/delete/${league_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_members_to_league_api = async (data) => {
  const requestObj = {
    path: `api/league/add_members`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const get_competition_series_list_api = async () => {
  const requestObj = {
    path: `api/league/series`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_competition_series_api = async (data) => {
  const requestObj = {
    path: `api/league/series`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_competition_series_api = async (data, id) => {
  const requestObj = {
    path: `api/league/series/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_competition_series_api = async (id) => {
  const requestObj = {
    path: `api/league/series/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_competition_sessions_api = async () => {
  const requestObj = {
    path: `api/league/sessions`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_competition_session_api = async (data) => {
  const requestObj = {
    path: `api/league/sessions`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_competition_session_api = async (data, id) => {
  const requestObj = {
    path: `api/league/sessions/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_competition_session_api = async (id) => {
  const requestObj = {
    path: `api/league/sessions/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_registrations_api = async (sessionId = '') => {
  const requestObj = {
    path: `api/league/registrations${sessionId ? `?session=${sessionId}` : ''}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const update_registration_api = async (data, id) => {
  const requestObj = {
    path: `api/league/registrations/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_registration_api = async (id) => {
  const requestObj = {
    path: `api/league//registrations/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_series_configs = async (data) => {
  const requestObj = {
    path: `api/league/get_series_configs`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const createSeriesConfig = async (data) => {
  const requestObj = {
    path: `api/league/admin_series_create`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet-async';
import { format } from 'date-fns';
import { 
  get_competition_sessions_api, 
  add_competition_session_api, 
  update_competition_session_api, 
  delete_competition_session_api,
  get_competition_series_list_api
} from '../../DAL/Leagues/League';
import SearchNotFound from '../../components/GeneralComponents/CustomMUITable/SearchNotFound';
import { CircularProgress } from '@mui/material';

export default function SessionManagement() {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [sessionsList, setSessionsList] = useState([]);
  const [seriesList, setSeriesList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentSession, setCurrentSession] = useState(null);
  const [formData, setFormData] = useState({
    series: '',
    startDate: '',
    endDate: '',
    registrationDeadline: '',
    status: 'pending',
    spacesAvailable: 0,
    spacesTotal: 0,
    subject: '',
    questionCount: 15,
    isVisible: true,
    notes: ''
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const [sessionsResponse, seriesResponse] = await Promise.all([
        get_competition_sessions_api(),
        get_competition_series_list_api()
      ]);
      
      setSessionsList(sessionsResponse.competitionSessions || []);
      setSeriesList(seriesResponse.competitionSeriesList || []);
    } catch (error) {
      enqueueSnackbar(error.message || 'Failed to fetch data', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDialog = (session = null) => {
    if (session) {
      setIsEditing(true);
      setCurrentSession(session);
      setFormData({
        series: session.series._id,
        startDate: format(new Date(session.startDate), "yyyy-MM-dd'T'HH:mm"),
        endDate: format(new Date(session.endDate), "yyyy-MM-dd'T'HH:mm"),
        registrationDeadline: format(new Date(session.registrationDeadline), "yyyy-MM-dd'T'HH:mm"),
        status: session.status,
        spacesAvailable: session.spacesAvailable,
        spacesTotal: session.spacesTotal,
        subject: session.subject,
        questionCount: session.questionCount,
        isVisible: session.isVisible,
        notes: session.notes || ''
      });
    } else {
      setIsEditing(false);
      setCurrentSession(null);
      
      // Default to first series if available
      const defaultSeries = seriesList.length > 0 ? seriesList[0]._id : '';
      const defaultSpaces = seriesList.length > 0 ? seriesList[0].participantCount : 5;
      
      setFormData({
        series: defaultSeries,
        startDate: '',
        endDate: '',
        registrationDeadline: '',
        status: 'pending',
        spacesAvailable: defaultSpaces,
        spacesTotal: defaultSpaces,
        subject: seriesList.length > 0 ? seriesList[0].subject : '',
        questionCount: seriesList.length > 0 ? seriesList[0].questionCount : 15,
        isVisible: true,
        notes: ''
      });
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSeriesChange = (e) => {
    const seriesId = e.target.value;
    const selectedSeries = seriesList.find(series => series._id === seriesId);
    
    if (selectedSeries) {
      setFormData({
        ...formData,
        series: seriesId,
        spacesTotal: selectedSeries.participantCount,
        spacesAvailable: selectedSeries.participantCount,
        subject: selectedSeries.subject,
        questionCount: selectedSeries.questionCount
      });
    } else {
      setFormData({
        ...formData,
        series: seriesId
      });
    }
  };

  const handleSubmit = async () => {
    try {
      if (isEditing) {
        await update_competition_session_api(formData, currentSession._id);
        enqueueSnackbar('Competition session updated successfully', { variant: 'success' });
      } else {
        await add_competition_session_api(formData);
        enqueueSnackbar('Competition session created successfully', { variant: 'success' });
      }
      handleCloseDialog();
      fetchData();
    } catch (error) {
      enqueueSnackbar(error.message || 'Failed to save competition session', { variant: 'error' });
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this competition session?')) {
      try {
        await delete_competition_session_api(id);
        enqueueSnackbar('Competition session deleted successfully', { variant: 'success' });
        fetchData();
      } catch (error) {
        enqueueSnackbar(error.message || 'Failed to delete competition session', { variant: 'error' });
      }
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'pending': return 'default';
      case 'open': return 'primary';
      case 'full': return 'warning';
      case 'in_progress': return 'info';
      case 'completed': return 'success';
      case 'cancelled': return 'error';
      default: return 'default';
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Helmet>
        <title>Competition Session Management | XAir Admin</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Competition Session Management
          </Typography>
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => handleOpenDialog()}
            disabled={seriesList.length === 0}
          >
            New Session
          </Button>
        </Stack>

        {seriesList.length === 0 && (
          <Box sx={{ mb: 3 }}>
            <Typography color="error">
              You need to create at least one competition series before creating sessions.
            </Typography>
          </Box>
        )}

        <Card>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Series</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>Subject</TableCell>
                    <TableCell>Spaces</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Visibility</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sessionsList.length > 0 ? (
                    sessionsList.map((session) => (
                      <TableRow key={session._id}>
                                                <TableCell>{session.series.name}</TableCell>
                        <TableCell>
                          {format(new Date(session.startDate), 'dd MMM yyyy HH:mm')}
                        </TableCell>
                        <TableCell>{session.subject}</TableCell>
                        <TableCell>
                          {session.spacesAvailable} / {session.spacesTotal}
                        </TableCell>
                        <TableCell>
                          <Chip 
                            label={session.status.replace('_', ' ')} 
                            color={getStatusColor(session.status)}
                            size="small"
                          />
                        </TableCell>
                        <TableCell>
                          <Chip 
                            label={session.isVisible ? 'Visible' : 'Hidden'} 
                            color={session.isVisible ? 'success' : 'default'}
                            size="small"
                          />
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Edit">
                            <IconButton onClick={() => handleOpenDialog(session)}>
                              <Edit />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton onClick={() => handleDelete(session._id)}>
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7}>
                        <SearchNotFound />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
        </Card>

        {/* Add/Edit Session Dialog */}
        <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
          <DialogTitle>{isEditing ? 'Edit Competition Session' : 'Add Competition Session'}</DialogTitle>
          <DialogContent>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  select
                  label="Competition Series"
                  name="series"
                  value={formData.series}
                  onChange={handleSeriesChange}
                  required
                >
                  {seriesList.map((series) => (
                    <MenuItem key={series._id} value={series._id}>
                      {series.name} ({series.participantCount} participants)
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Start Date & Time"
                  name="startDate"
                  type="datetime-local"
                  value={formData.startDate}
                  onChange={handleInputChange}
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="End Date & Time"
                  name="endDate"
                  type="datetime-local"
                  value={formData.endDate}
                  onChange={handleInputChange}
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </Grid>
              
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Registration Deadline"
                  name="registrationDeadline"
                  type="datetime-local"
                  value={formData.registrationDeadline}
                  onChange={handleInputChange}
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  select
                  label="Status"
                  name="status"
                  value={formData.status}
                  onChange={handleInputChange}
                  required
                >
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="open">Open</MenuItem>
                  <MenuItem value="full">Full</MenuItem>
                  <MenuItem value="in_progress">In Progress</MenuItem>
                  <MenuItem value="completed">Completed</MenuItem>
                  <MenuItem value="cancelled">Cancelled</MenuItem>
                </TextField>
              </Grid>
              
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Total Spaces"
                  name="spacesTotal"
                  type="number"
                  value={formData.spacesTotal}
                  onChange={handleInputChange}
                  required
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Available Spaces"
                  name="spacesAvailable"
                  type="number"
                  value={formData.spacesAvailable}
                  onChange={handleInputChange}
                  required
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  select
                  label="Visibility"
                  name="isVisible"
                  value={formData.isVisible}
                  onChange={(e) => setFormData({...formData, isVisible: e.target.value === 'true'})}
                >
                  <MenuItem value="true">Visible</MenuItem>
                  <MenuItem value="false">Hidden</MenuItem>
                </TextField>
              </Grid>
              
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleInputChange}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Question Count"
                  name="questionCount"
                  type="number"
                  value={formData.questionCount}
                  onChange={handleInputChange}
                  required
                />
              </Grid>
              
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Notes"
                  name="notes"
                  value={formData.notes}
                  onChange={handleInputChange}
                  multiline
                  rows={3}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button onClick={handleSubmit} variant="contained">
              {isEditing ? 'Update' : 'Create'}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}

import React from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PaidIcon from "@mui/icons-material/Paid";
import HikingIcon from "@mui/icons-material/Hiking";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import { EmojiEvents, Group, CalendarToday } from '@mui/icons-material';

const get_icon = (name) => {
  // Mapping icon names to MUI icons
  const icons = {
    dashboard: <DashboardIcon />,
    passengers: <HikingIcon />,
    pilots: <PaidIcon />,
    airports: <AirportShuttleIcon />,
    flights: <FlightTakeoffIcon />,
    leagues: <EmojiEvents />,
    // Add more mappings as needed
  };

  return icons[name] || null; // Return the icon or null if not found
};

const sidebarConfig = () => {
  let sidebarMenus = [
    {
      title: "Dashboard",
      path: "/dashboard",
      icon: get_icon("dashboard"),
    },
    {
      title: "Passengers List",
      path: "/passengers-list",
      icon: get_icon("passengers"),
    },
    {
      title: "Pilots List",
      path: "/pilots-list",
      icon: get_icon("pilots"),
    },
    {
      title: "Airports List",
      path: "/airports-list",
      icon: get_icon("airports"),
    },
    {
      title: "Flights List",
      path: "/flights-list",
      icon: get_icon("flights"),
    },
    {
      title: 'Competition',
      path: '/leagues-list',
      icon: <EmojiEvents />,
    },
    {
      title: 'Series Management',
      path: '/leagues/series',
      icon: <EmojiEvents />,
    },
    {
      title: 'Session Management',
      path: '/leagues/sessions',
      icon: <EmojiEvents />,
    },
    {
      title: 'Participants',
      path: '/leagues/participants',
      icon: <EmojiEvents />,
    },
    {
      title: 'Leaderboard',
      path: '/leagues/leaderboard',
      icon: <EmojiEvents />,
    }
  ];

  return sidebarMenus;
};

export default sidebarConfig;
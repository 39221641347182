import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { Save, EmojiEvents, Email } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet-async';
import { format } from 'date-fns';
import { 
  get_registrations_api, 
  update_registration_api,
  get_competition_sessions_api
} from '../../DAL/Leagues/League';
import SearchNotFound from '../../components/GeneralComponents/CustomMUITable/SearchNotFound';
import { CircularProgress } from '@mui/material';

export default function LeaderboardManagement() {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [sessions, setSessions] = useState([]);
  const [selectedSession, setSelectedSession] = useState('');
  const [participants, setParticipants] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [bulkEditMode, setBulkEditMode] = useState(false);
  const [emailConfirmOpen, setEmailConfirmOpen] = useState(false);

  useEffect(() => {
    fetchSessions();
  }, []);

  useEffect(() => {
    if (selectedSession) {
      fetchParticipants();
    }
  }, [selectedSession]);

  const fetchSessions = async () => {
    setLoading(true);
    try {
      const response = await get_competition_sessions_api();
      const completedSessions = response.competitionSessions.filter(
        session => session.status === 'completed' || session.status === 'in_progress'
      );
      setSessions(completedSessions);
      
      if (completedSessions.length > 0) {
        setSelectedSession(completedSessions[0]._id);
      } else {
        setLoading(false);
      }
    } catch (error) {
      enqueueSnackbar(error.message || 'Failed to fetch sessions', { variant: 'error' });
      setLoading(false);
    }
  };

  const fetchParticipants = async () => {
    setLoading(true);
    try {
      const response = await get_registrations_api(selectedSession);
      const paidParticipants = response.registrations.filter(
        reg => reg.status === 'paid' || reg.status === 'completed'
      );
      
      // Sort by score (descending)
      const sortedParticipants = paidParticipants.sort((a, b) => 
        (b.score || 0) - (a.score || 0)
      );
      
      // Add temporary ranking
      const rankedParticipants = sortedParticipants.map((participant, index) => ({
        ...participant,
        tempRanking: index + 1
      }));
      
      setParticipants(rankedParticipants);
    } catch (error) {
      enqueueSnackbar(error.message || 'Failed to fetch participants', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleSessionChange = (event) => {
    setSelectedSession(event.target.value);
  };

  const handleScoreChange = (id, score) => {
    const updatedParticipants = participants.map(participant => {
      if (participant._id === id) {
        return { ...participant, score };
      }
      return participant;
    });
    
    // Re-sort and update rankings
    const sortedParticipants = updatedParticipants.sort((a, b) => 
      (b.score || 0) - (a.score || 0)
    );
    
    const rankedParticipants = sortedParticipants.map((participant, index) => ({
      ...participant,
      tempRanking: index + 1
    }));
    
    setParticipants(rankedParticipants);
  };

  const saveResults = async () => {
    setBulkEditMode(true);
    setOpenDialog(true);
  };

  const confirmSaveResults = async () => {
    setOpenDialog(false);
    setLoading(true);
    
    try {
      // Update each participant with their score and ranking
      const updatePromises = participants.map(participant => 
        update_registration_api({
          score: participant.score || 0,
          ranking: participant.tempRanking,
          status: 'completed'
        }, participant._id)
      );
      
      await Promise.all(updatePromises);
      
      // Get the selected session details
      const sessionResponse = await get_competition_sessions_api();
      const session = sessionResponse.competitionSessions.find(s => s._id === selectedSession);
      
      // Update session status if needed
      if (session && session.status !== 'completed') {
        // This would require an additional API endpoint to update session status
        // For now, we'll just show a success message
      }
      
      enqueueSnackbar('Results saved successfully', { variant: 'success' });
      fetchParticipants();
    } catch (error) {
      enqueueSnackbar(error.message || 'Failed to save results', { variant: 'error' });
    } finally {
      setLoading(false);
      setBulkEditMode(false);
    }
  };

  const sendResultEmails = () => {
    setEmailConfirmOpen(true);
  };

  const confirmSendEmails = async () => {
    setEmailConfirmOpen(false);
    setLoading(true);
    
    try {
      // This would call an API endpoint to send emails
      // For now, we'll just simulate success
      setTimeout(() => {
        enqueueSnackbar('Result emails sent successfully', { variant: 'success' });
        setLoading(false);
      }, 2000);
    } catch (error) {
      enqueueSnackbar(error.message || 'Failed to send emails', { variant: 'error' });
      setLoading(false);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  const selectedSessionData = sessions.find(s => s._id === selectedSession);
  const isSessionCompleted = selectedSessionData?.status === 'completed';

  return (
    <>
      <Helmet>
        <title>Leaderboard Management | XAir Admin</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Leaderboard Management
          </Typography>
          <Box>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Save />}
              onClick={saveResults}
              disabled={!selectedSession || participants.length === 0 || isSessionCompleted}
              sx={{ mr: 2 }}
            >
              Save Results
            </Button>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Email />}
              onClick={sendResultEmails}
              disabled={!selectedSession || participants.length === 0 || !isSessionCompleted}
            >
              Send Result Emails
            </Button>
          </Box>
        </Stack>

        {sessions.length === 0 ? (
          <Paper sx={{ p: 3, textAlign: 'center' }}>
            <Typography variant="subtitle1">
              No completed or in-progress sessions available for leaderboard management.
            </Typography>
          </Paper>
        ) : (
          <>
            <Box sx={{ mb: 3 }}>
              <FormControl fullWidth sx={{ maxWidth: 500 }}>
                <InputLabel>Select Session</InputLabel>
                <Select
                  value={selectedSession}
                  onChange={handleSessionChange}
                  label="Select Session"
                >
                  {sessions.map((session) => (
                    <MenuItem key={session._id} value={session._id}>
                      {session.series.name} - {format(new Date(session.startDate), 'dd MMM yyyy')}
                      {session.status === 'completed' ? ' (Completed)' : ' (In Progress)'}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {selectedSessionData && (
              <Box sx={{ mb: 3 }}>
                <Paper sx={{ p: 2 }}>
                  <Typography variant="h6">
                    {selectedSessionData.series.name}
                  </Typography>
                  <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body2" color="text.secondary">
                        Date: {format(new Date(selectedSessionData.startDate), 'dd MMM yyyy')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body2" color="text.secondary">
                        Subject: {selectedSessionData.subject}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body2" color="text.secondary">
                        Status: {selectedSessionData.status.replace('_', ' ')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
            )}

            <Card>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Rank</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Score</TableCell>
                        <TableCell>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {participants.length > 0 ? (
                        participants.map((participant) => (
                          <TableRow key={participant._id}>
                            <TableCell>
                              {participant.tempRanking === 1 && (
                                <EmojiEvents color="warning" sx={{ mr: 1, verticalAlign: 'middle' }} />
                              )}
                              {participant.tempRanking}
                            </TableCell>
                            <TableCell>
                              {participant.firstName} {participant.lastName}
                            </TableCell>
                            <TableCell>{participant.email}</TableCell>
                            <TableCell>
                              {isSessionCompleted ? (
                                participant.score || 0
                              ) : (
                                <TextField
                                  type="number"
                                  size="small"
                                  value={participant.score || 0}
                                  onChange={(e) => handleScoreChange(participant._id, Number(e.target.value))}
                                  InputProps={{ inputProps: { min: 0, max: 100 } }}
                                  sx={{ width: 80 }}
                                />
                              )}
                            </TableCell>
                            <TableCell>{participant.status}</TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={5}>
                            <SearchNotFound searchQuery="No participants found for this session" />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
            </Card>
          </>
        )}

        {/* Confirm Save Dialog */}
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle>Confirm Save Results</DialogTitle>
          <DialogContent>
            <Typography>
              This will save the current scores and rankings for all participants and mark them as completed.
              Are you sure you want to continue?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
            <Button onClick={confirmSaveResults} variant="contained" color="primary">
              Save Results
            </Button>
          </DialogActions>
        </Dialog>

        {/* Confirm Email Dialog */}
        <Dialog open={emailConfirmOpen} onClose={() => setEmailConfirmOpen(false)}>
          <DialogTitle>Send Result Emails</DialogTitle>
          <DialogContent>
            <Typography>
              This will send result notification emails to all participants.
              Are you sure you want to continue?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEmailConfirmOpen(false)}>Cancel</Button>
            <Button onClick={confirmSendEmails} variant="contained" color="secondary">
              Send Emails
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}

import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  MenuItem,
  Paper,
  Tooltip
} from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet-async';
import { 
  get_series_configs,
  createSeriesConfig
} from '../../DAL/Leagues/League';
import SearchNotFound from '../../components/GeneralComponents/CustomMUITable/SearchNotFound';
import { CircularProgress } from '@mui/material';

const subjects = [
  { value: "air-law", label: "Air Law" },
  { value: "human-performance", label: "Human Performance" },
  { value: "meteorology", label: "Meteorology" },
  { value: "navigation", label: "Navigation" },
  { value: "operational-procedures", label: "Operational Procedures" },
  { value: "principles-of-flight", label: "Principles of Flight" },
  { value: "aircraft-general-knowledge", label: "Aircraft General Knowledge" },
  { value: "flight-performance-planning", label: "Flight Performance & Planning" },
  { value: "communications", label: "Communications" }
];

export default function SeriesManagement() {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [seriesList, setSeriesList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentSeries, setCurrentSeries] = useState(null);
  
  // Initialize form data based on the backend schema
  const [formData, setFormData] = useState({
    name: '',
    participant_limit: '',
    total_prize_pool: '',
    prize_distribution: [
      { position: 1, amount: '' },
      { position: 2, amount: '' },
      { position: 3, amount: '' }
    ],
    question_count: 15,
    category: 'PPL', // Valid default
    subject: 'air-law', // Valid default
    topic: 'all',
    is_active: true
  });
  
  const [prizeRows, setPrizeRows] = useState(3);

  useEffect(() => {
    fetchSeriesList();
  }, []);

  const fetchSeriesList = async () => {
    setLoading(true);
    try {
      const response = await get_series_configs();
      if (response && response.seriesConfigs) {
        setSeriesList(response.seriesConfigs);
      } else {
        setSeriesList([]);
      }
    } catch (error) {
      console.error("Error fetching series configs:", error);
      enqueueSnackbar(error.message || 'Failed to fetch series configurations', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDialog = (series = null) => {
    if (series) {
      setIsEditing(true);
      setCurrentSeries(series);
      setFormData({
        name: series.name,
        participant_limit: series.participant_limit,
        total_prize_pool: series.total_prize_pool,
        prize_distribution: [...series.prize_distribution],
        question_count: series.question_count,
        category: series.category,
        subject: series.subject,
        topic: series.topic || 'all',
        is_active: series.is_active
      });
      setPrizeRows(series.prize_distribution.length);
    } else {
      setIsEditing(false);
      setCurrentSeries(null);
      setFormData({
        name: '',
        participant_limit: 10,
        total_prize_pool: 100,
        prize_distribution: [
          { position: 1, amount: 50 },
          { position: 2, amount: 30 },
          { position: 3, amount: 20 }
        ],
        question_count: 15,
        category: 'general',
        subject: '',
        topic: 'all',
        is_active: true
      });
      setPrizeRows(3);
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name === 'participant_limit' || name === 'question_count' || name === 'total_prize_pool' 
        ? Number(value) 
        : value
    });
  };

  const handlePrizeDistributionChange = (index, field, value) => {
    const updatedDistribution = [...formData.prize_distribution];
    updatedDistribution[index] = {
      ...updatedDistribution[index],
      [field]: field === 'amount' ? Number(value) : value
    };
    setFormData({
      ...formData,
      prize_distribution: updatedDistribution
    });
  };

  const addPrizeRow = () => {
    const newRow = {
      position: prizeRows + 1,
      amount: 0
    };
    setFormData({
      ...formData,
      prize_distribution: [...formData.prize_distribution, newRow]
    });
    setPrizeRows(prizeRows + 1);
  };

  const removePrizeRow = (index) => {
    if (prizeRows <= 1) return;
    
    const updatedDistribution = formData.prize_distribution.filter((_, i) => i !== index);
    // Reorder positions
    updatedDistribution.forEach((item, i) => {
      item.position = i + 1;
    });
    
    setFormData({
      ...formData,
      prize_distribution: updatedDistribution
    });
    setPrizeRows(prizeRows - 1);
  };

  const validateForm = () => {
    // Check required fields
    if (!formData.name || !formData.participant_limit || !formData.total_prize_pool || 
        !formData.category || !formData.subject) {
      enqueueSnackbar('Please fill all required fields', { variant: 'error' });
      return false;
    }

    // Validate prize distribution
    if (!formData.prize_distribution || formData.prize_distribution.length === 0) {
      enqueueSnackbar('Prize distribution is required', { variant: 'error' });
      return false;
    }

    // Check if each prize has position and amount
    for (const prize of formData.prize_distribution) {
      if (!prize.position || typeof prize.amount !== 'number') {
        enqueueSnackbar('Each prize must have a position and amount', { variant: 'error' });
        return false;
      }
    }

    // Verify sum of prizes matches total prize pool
    const sumOfPrizes = formData.prize_distribution.reduce((sum, prize) => sum + prize.amount, 0);
    if (Math.abs(sumOfPrizes - formData.total_prize_pool) > 0.01) {
      enqueueSnackbar(`Sum of prizes (${sumOfPrizes}) does not match the total prize pool (${formData.total_prize_pool})`, { variant: 'error' });
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    try {
      if (isEditing) {
        // Implement update functionality when needed
        enqueueSnackbar('Update functionality not implemented yet', { variant: 'info' });
      } else {
        await createSeriesConfig(formData);
        enqueueSnackbar('Series configuration created successfully', { variant: 'success' });
      }
      handleCloseDialog();
      fetchSeriesList();
    } catch (error) {
      enqueueSnackbar(error.message || 'Failed to save series configuration', { variant: 'error' });
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this series configuration?')) {
      try {
        // Implement delete functionality when needed
        enqueueSnackbar('Delete functionality not implemented yet', { variant: 'info' });
        fetchSeriesList();
      } catch (error) {
        enqueueSnackbar(error.message || 'Failed to delete series configuration', { variant: 'error' });
      }
    }
  };

  if (loading) {
    return (
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh',
        flexDirection: 'column'
      }}>
        <CircularProgress color="primary" />
        <Typography variant="h6" style={{ marginTop: 16 }}>
          Loading Series Configurations...
        </Typography>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Series Configuration Management | XAir Admin</title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Series Configuration Management
          </Typography>
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => handleOpenDialog()}
          >
            New Series Config
          </Button>
        </Stack>

        <Card>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Participant Limit</TableCell>
                  <TableCell>Prize Pool</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Subject</TableCell>
                  <TableCell>Questions</TableCell>
                  <TableCell>Reference Code</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {seriesList.length > 0 ? (
                  seriesList.map((series) => (
                    <TableRow key={series._id}>
                      <TableCell>{series.name}</TableCell>
                      <TableCell>{series.participant_limit}</TableCell>
                      <TableCell>${series.total_prize_pool}</TableCell>
                      <TableCell>{series.category}</TableCell>
                      <TableCell>{series.subject}</TableCell>
                      <TableCell>{series.question_count}</TableCell>
                      <TableCell>{series.reference_code}</TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            color: series.is_active ? 'success.main' : 'error.main',
                          }}
                        >
                          {series.is_active ? 'Active' : 'Inactive'}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Edit">
                          <IconButton onClick={() => handleOpenDialog(series)}>
                            <Edit />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton onClick={() => handleDelete(series._id)}>
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={9}>
                      <SearchNotFound />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>

        {/* Add/Edit Series Dialog */}
        <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
          <DialogTitle>{isEditing ? 'Edit Series Configuration' : 'Add Series Configuration'}</DialogTitle>
          <DialogContent>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Series Name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Participant Limit"
                  name="participant_limit"
                  type="number"
                  value={formData.participant_limit}
                  onChange={handleInputChange}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  select
                  label="Category"
                  name="category"
                  value={formData.category}
                  onChange={handleInputChange}
                  required
                >
                  <MenuItem value="PPL">PPL</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  select
                  label="Subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleInputChange}
                  required
                >
                  {subjects.map((subject) => (
                    <MenuItem key={subject.value} value={subject.value}>
                      {subject.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Topic"
                  name="topic"
                  value={formData.topic}
                  onChange={handleInputChange}
                  helperText="Default is 'all'"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Question Count"
                  name="question_count"
                  type="number"
                  value={formData.question_count}
                  onChange={handleInputChange}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Total Prize Pool (£)"
                  name="total_prize_pool"
                  type="number"
                  value={formData.total_prize_pool}
                  onChange={handleInputChange}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  select
                  label="Status"
                  name="is_active"
                  value={formData.is_active}
                  onChange={(e) => setFormData({...formData, is_active: e.target.value === 'true'})}
                >
                  <MenuItem value="true">Active</MenuItem>
                  <MenuItem value="false">Inactive</MenuItem>
                </TextField>
              </Grid>
              
              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                  Prize Distribution
                </Typography>
                <Paper variant="outlined" sx={{ p: 2 }}>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Position</TableCell>
                          <TableCell>Amount ($)</TableCell>
                          <TableCell align="center">Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {formData.prize_distribution.map((prize, index) => (
                          <TableRow key={index}>
                            <TableCell>{prize.position}</TableCell>
                            <TableCell>
                              <TextField
                                size="small"
                                type="number"
                                value={prize.amount}
                                onChange={(e) => handlePrizeDistributionChange(index, 'amount', Number(e.target.value))}
                                sx={{ width: '100px' }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <IconButton 
                                size="small" 
                                color="error" 
                                onClick={() => removePrizeRow(index)}
                                disabled={prizeRows <= 1}
                              >
                                <Delete />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="body2" color="text.secondary">
                      Total: ${formData.prize_distribution.reduce((sum, prize) => sum + prize.amount, 0)} 
                      {formData.prize_distribution.reduce((sum, prize) => sum + prize.amount, 0) !== formData.total_prize_pool && 
                        <Typography component="span" color="error.main"> 
                          (Must match total prize pool of ${formData.total_prize_pool})
                        </Typography>
                      }
                    </Typography>
                    <Button 
                      startIcon={<Add />} 
                      onClick={addPrizeRow}
                      variant="outlined"
                      size="small"
                    >
                      Add Position
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button onClick={handleSubmit} variant="contained">
              {isEditing ? 'Update' : 'Create'}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}

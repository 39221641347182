import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Chip,
  Avatar,
  Tooltip,
  Paper,
  InputAdornment,
  IconButton,
  Tabs,
  Tab,
  Box,
  Autocomplete,
  Divider,
  Card,
  CardContent,
  CardHeader,
  Badge,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import ConfirmationPopup from "../../components/GeneralComponents/ConfirmationPopup";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  get_leagues_list_api,
  add_league_api,
  delete_league_api,
  add_members_to_league_api,
} from "../../DAL/Leagues/League";
import { get_member_list_api } from "../../DAL/Passenger/Passenger";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import FilterListIcon from "@mui/icons-material/FilterList";
import RefreshIcon from "@mui/icons-material/Refresh";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";

const subjects = [
  { value: "air-law", label: "Air Law" },
  { value: "human-performance", label: "Human Performance" },
  { value: "meteorology", label: "Meteorology" },
  { value: "navigation", label: "Navigation" },
  { value: "operational-procedures", label: "Operational Procedures" },
  { value: "principles-of-flight", label: "Principles of Flight" },
  {
    value: "aircraft-general-knowledge",
    label: "Aircraft General Knowledge",
  },
  {
    value: "flight-performance-planning",
    label: "Flight Performance & Planning",
  },
  { value: "communications", label: "Communications" },
];

function LeaguesList() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [leaguesList, setLeaguesList] = useState([]);
  const [filteredLeagues, setFilteredLeagues] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [searchMemberQuery, setSearchMemberQuery] = useState("");
  const [loadingLeagues, setLoadingLeagues] = useState(new Set());
  const [searchQuery, setSearchQuery] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [filterSubject, setFilterSubject] = useState("all");
  const [passengersList, setPassengersList] = useState([]);
  const [leagueDetailsMap, setLeagueDetailsMap] = useState({});
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedLeague, setSelectedLeague] = useState(null);

  // New state for create league dialog
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [newLeague, setNewLeague] = useState({
    name: "",
    description: "",
    category: "PPL",
    subject: subjects[0].value,
    topic: "all",
    numberOfQuestions: 5,
  });

  // New state for invite dialog
  const [openInviteDialog, setOpenInviteDialog] = useState(false);
  const [selectedPassengers, setSelectedPassengers] = useState([]);
  const [inviteLeague, setInviteLeague] = useState(null);

  const TABLE_HEAD = [
    { id: "_id", label: "#", alignRight: false, type: "number" },
    { id: "name", label: "Name", alignRight: false },
    { id: "category", label: "Category", alignRight: false },
    { id: "subject", label: "Subject", alignRight: false },
    { id: "topic", label: "Topic", alignRight: false },
    { id: "questionsLength", label: "Questions", alignRight: false },
    {
      id: "membersLength",
      label: "Total Members",
      alignRight: false,
    },
    { id: "members", label: "Participants", alignRight: false, type: "custom" },
    { id: "reference_code", label: "Reference Code", alignRight: false },
    { id: "action", label: "Action", alignRight: false, type: "action" },
  ];

  const handleInvite = (value) => {
    console.log("Invite clicked for league:", value);
    setInviteLeague(value);
    setOpenInviteDialog(true);
  };

  const handleAgreeDelete = (data) => {
    setSelectedObject(data);
    setOpenDelete(true);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: (value) => {
        navigate(`/leagues-list/edit-league/${value._id}`, {
          state: value,
        });
      },
    },
    {
      label: "Invite",
      icon: "mdi:account-multiple-plus",
      handleClick: (value) => {
        // Find the original league data from leaguesList
        const originalLeague = leaguesList.find(
          (league) => league._id === value._id
        );
        handleInvite(originalLeague);
      },
    },
    {
      label: "View Details",
      icon: "carbon:view",
      handleClick: (value) => {
        // Find the original league data from leaguesList
        const originalLeague = leaguesList.find(
          (league) => league._id === value._id
        );
        setSelectedLeague(originalLeague);
        setIsDrawerOpen(true);
      },
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_league_api(selectedObject._id);
    if (result.code === 200) {
      setLeaguesList(
        leaguesList.filter((league) => league._id !== selectedObject._id)
      );
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const filteredPassengers = passengersList.filter((passenger) => {
    const fullName =
      `${passenger.first_name} ${passenger.last_name} ${passenger.email}`.toLowerCase();
    return fullName.includes(searchMemberQuery.toLowerCase());
  });

  const handleCreateLeagueDialog = () => {
    setOpenCreateDialog(true);
  };

  const handleCloseCreateDialog = () => {
    setOpenCreateDialog(false);
    setNewLeague({
      name: "",
      description: "",
      category: "PPL",
      subject: "",
      topic: "all",
      numberOfQuestions: 5,
    });
  };

  const handleCreateLeague = async () => {
    if (!newLeague.category || !newLeague.subject || !newLeague.topic) {
      enqueueSnackbar("Category, subject, and topic are required", {
        variant: "error",
      });
      return;
    }

    setOpenCreateDialog(false);

    // Add temporary league with loading state
    const tempId = `temp-${Date.now()}`;
    const tempLeague = {
      _id: tempId,
      ...newLeague,
      questionsLength: 0,
      membersLength: 0,
      isLoading: true,
    };

    setLeaguesList([...leaguesList, tempLeague]);
    setLoadingLeagues(new Set([...loadingLeagues, tempId]));

    const result = await add_league_api(newLeague);
    if (result.code === 200) {
      const newLeagueData = result.league;
      setLeaguesList((currentList) =>
        currentList.map((league) =>
          league._id === tempId
            ? {
                ...newLeagueData,
                questionsLength: newLeagueData.questions.length,
                membersLength: newLeagueData.members.length,
                isLoading: false,
              }
            : league
        )
      );
      enqueueSnackbar("League created successfully", { variant: "success" });
    } else {
      // Remove temporary league if creation failed
      setLeaguesList((currentList) =>
        currentList.filter((league) => league._id !== tempId)
      );
      enqueueSnackbar(result.message || "Error creating league", {
        variant: "error",
      });
    }
    setLoadingLeagues((current) => {
      const updated = new Set(current);
      updated.delete(tempId);
      return updated;
    });
    handleCloseCreateDialog();
  };

  const handleLeagueInputChange = (e) => {
    const { name, value } = e.target;
    setNewLeague({
      ...newLeague,
      [name]: value,
    });
  };

  const getPassengerList = async () => {
    try {
      const result = await get_member_list_api();
      if (result.code === 200) {
        console.log(result);
        const data = result.member_list;
        setPassengersList(data);
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching passenger list:", error);
      enqueueSnackbar("Error fetching passenger list", { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleCloseInviteDialog = () => {
    setOpenInviteDialog(false);
    setSelectedPassengers([]);
    setInviteLeague(null);
  };

  const handlePassengerSelection = (event) => {
    console.log(event.target.value);
    setSelectedPassengers(event.target.value);
  };

  const handleAddMembers = async () => {
    if (!selectedPassengers.length) {
      enqueueSnackbar("Please select at least one passenger", {
        variant: "error",
      });
      return;
    }

    try {
      const result = await add_members_to_league_api({
        leagueId: inviteLeague._id,
        members: selectedPassengers,
      });

      if (result.code === 200) {
        // Update the league in the list with the new members count
        const updatedLeaguesList = leaguesList.map((league) => {
          if (league._id === inviteLeague._id) {
            return {
              ...league,
              membersLength: result.league.members.length,
              questionsLength: result.league.questions.length,
              members: result.league.members,
            };
          }
          return league;
        });

        setLeaguesList(updatedLeaguesList);
        enqueueSnackbar("Members added to league successfully", {
          variant: "success",
        });
        handleCloseInviteDialog();
      } else {
        enqueueSnackbar(result.message || "Failed to add members", {
          variant: "error",
        });
      }
    } catch (error) {
      enqueueSnackbar("Error adding members to league", { variant: "error" });
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleRefresh = async () => {
    setIsLoading(true);
    await fetchLeaguesList();
  };

  const fetchLeaguesList = async () => {
    try {
      const result = await get_leagues_list_api();
      if (result.code === 200) {
        setLeaguesList(
          result.league_list.map((league) => ({
            ...league,
            questionsLength: league.questions.length,
            membersLength: league.members.length,
          }))
        );
      } else {
        setErrorMessage(result.message || "Failed to fetch leagues.");
      }
    } catch (error) {
      setErrorMessage("Error fetching leagues list.");
    }
    setIsLoading(false);
  };

  // Function to map member IDs to actual member data
  const mapMembersToNames = (memberIds) => {
    return memberIds.map((id) => {
      const member = passengersList.find((p) => p._id === id);
      return member
        ? {
            id,
            name: `${member.first_name} ${member.last_name}`,
            email: member.email,
            avatar: member.profile_image || null,
          }
        : { id, name: "Unknown User", email: "" };
    });
  };

  // Filter leagues based on search query and selected tab
  useEffect(() => {
    console.log("Leagues List:", leaguesList);
    console.log("Search Query:", searchQuery);
    console.log("Tab Value:", tabValue);
    console.log("Filter Subject:", filterSubject);
    let filtered = [...leaguesList];

    // Apply search filter
    if (searchQuery) {
      filtered = filtered.filter(
        (league) =>
          league.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          league.category?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          league.subject?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          league.reference_code?.includes(searchQuery)
      );
    }

    // Apply tab filter
    if (tabValue === 1) {
      // Most Members
      filtered.sort((a, b) => b.membersLength - a.membersLength);
    } else if (tabValue === 2) {
      // Most Questions
      filtered.sort((a, b) => b.questionsLength - a.questionsLength);
    }

    // Apply subject filter
    if (filterSubject !== "all") {
      filtered = filtered.filter((league) => league.subject === filterSubject);
    }

    console.log("Filtered Leagues:", filtered);

    setFilteredLeagues(filtered);
  }, [searchQuery, tabValue, filterSubject, leaguesList]);

  useEffect(() => {
    fetchLeaguesList();
    getPassengerList();
  }, []);

  useEffect(() => {
    console.log("Invite League:", inviteLeague);
    setSelectedPassengers(inviteLeague?.members || []);
  }, [inviteLeague]);

  // Create a map of league IDs to member details
  useEffect(() => {
    if (leaguesList.length > 0 && passengersList.length > 0) {
      const detailsMap = {};
      leaguesList.forEach((league) => {
        detailsMap[league._id] = mapMembersToNames(league.members || []);
      });
      setLeagueDetailsMap(detailsMap);
    }
  }, [leaguesList, passengersList]);

  // Custom renderer for members column
  const renderMembersColumn = (row) => {
    const members = leagueDetailsMap[row._id] || [];
    const displayCount = 3;
    const displayMembers = members.slice(0, displayCount);
    const remainingCount = members.length - displayCount;

    return (
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
        {displayMembers.map((member, index) => (
          <Tooltip key={index} title={member.email || member.name}>
            <Chip
              avatar={
                member.avatar ? (
                  <Avatar src={member.avatar} />
                ) : (
                  <Avatar>{member.name.charAt(0)}</Avatar>
                )
              }
              label={member.name}
              size="small"
              sx={{ maxWidth: 120 }}
            />
          </Tooltip>
        ))}
        {remainingCount > 0 && (
          <Tooltip title={`${remainingCount} more members`}>
            <Chip
              label={`+${remainingCount}`}
              size="small"
              color="primary"
              onClick={() => {
                setSelectedLeague(row);
                setIsDrawerOpen(true);
              }}
            />
          </Tooltip>
        )}
      </Box>
    );
  };

  // League details drawer content
  const LeagueDetailsDrawer = () => {
    const [memberSearchQuery, setMemberSearchQuery] = useState("");
    if (!selectedLeague) return null;

    const members = leagueDetailsMap[selectedLeague._id] || [];

    const filteredMembers = memberSearchQuery
      ? members.filter(
          (m) =>
            m.name.toLowerCase().includes(memberSearchQuery.toLowerCase()) ||
            m.email.toLowerCase().includes(memberSearchQuery.toLowerCase())
        )
      : members;

    return (
      <Box
        sx={{
          p: 2,
          height: "100%",
          overflow: "auto",
          color: "#ffffff",
          "& .MuiTypography-root": {
            color: "#ffffff",
          },
          "& .MuiTypography-colorTextSecondary": {
            color: "#b5b5c3",
          },
          "& .MuiPaper-root": {
            backgroundColor: "rgba(30, 30, 45, 0.7)",
          },
          "& .MuiDivider-root": {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
          },
        }}
      >
        <Typography variant="h5" gutterBottom>
          {selectedLeague.name ||
            `${selectedLeague.category} - ${selectedLeague.subject}`}
        </Typography>

        <Box sx={{ my: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            League Details
          </Typography>
          <Paper sx={{ p: 2, bgcolor: "rgba(255,255,255,0.05)" }}>
            <Box
              sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 2 }}
            >
              <Typography>
                <strong>Category:</strong> {selectedLeague.category}
              </Typography>
              <Typography>
                <strong>Subject:</strong> {selectedLeague.subject}
              </Typography>
              <Typography>
                <strong>Topic:</strong> {selectedLeague.topic}
              </Typography>
              <Typography>
                <strong>Questions:</strong> {selectedLeague.questionsLength}
              </Typography>
              {selectedLeague.reference_code && (
                <Typography>
                  <strong>Reference Code:</strong>{" "}
                  {selectedLeague.reference_code}
                </Typography>
              )}
            </Box>
          </Paper>
        </Box>

        <Box sx={{ my: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="subtitle1">
              Members ({members.length})
            </Typography>
            <Button
              startIcon={<PersonAddIcon />}
              variant="outlined"
              size="small"
              onClick={() => {
                setInviteLeague(selectedLeague);
                setOpenInviteDialog(true);
              }}
            >
              Add Members
            </Button>
          </Box>

          <TextField
            fullWidth
            placeholder="Search members..."
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ mb: 2 }}
          />

          <Paper
            sx={{
              maxHeight: 400,
              overflow: "auto",
              bgcolor: "rgba(255,255,255,0.05)",
            }}
          >
            {filteredMembers.length > 0 ? (
              filteredMembers.map((member, index) => (
                <Box
                  key={index}
                  sx={{
                    p: 1.5,
                    display: "flex",
                    alignItems: "center",
                    borderBottom:
                      index < filteredMembers.length - 1
                        ? "1px solid rgba(255,255,255,0.1)"
                        : "none",
                  }}
                >
                  <Avatar src={member.avatar} sx={{ mr: 2 }}>
                    {member.name.charAt(0)}
                  </Avatar>
                  <Box>
                    <Typography variant="body1">{member.name}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      {member.email}
                    </Typography>
                  </Box>
                </Box>
              ))
            ) : (
              <Box sx={{ p: 2, textAlign: "center" }}>
                <Typography color="text.secondary">No members found</Typography>
              </Box>
            )}
          </Paper>
        </Box>
      </Box>
    );
  };

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-12">
            <Paper
              className="theme-card p-3"
              elevation={3}
              sx={{
                backgroundColor: "#1e1e2d", // Darker background
                color: "#ffffff", // White text
              }}
            >
              <Box sx={{ mb: 3 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Typography variant="h4" component="h1">
                    Leagues Management
                  </Typography>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Button
                      variant="outlined"
                      startIcon={<RefreshIcon />}
                      onClick={handleRefresh}
                    >
                      Refresh
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={handleCreateLeagueDialog}
                    >
                      Create League
                    </Button>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    placeholder="Search leagues or reference code..."
                    variant="outlined"
                    size="small"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    sx={{ flexGrow: 1, maxWidth: 300 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <FormControl size="small" sx={{ minWidth: 200 }}>
                    <InputLabel>Filter by Subject</InputLabel>
                    <Select
                      value={filterSubject}
                      onChange={(e) => setFilterSubject(e.target.value)}
                      label="Filter by Subject"
                    >
                      <MenuItem value="all">All Subjects</MenuItem>
                      {subjects.map((subject) => (
                        <MenuItem key={subject.value} value={subject.value}>
                          {subject.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Box sx={{ flexGrow: 1 }}>
                    <Tabs
                      value={tabValue}
                      onChange={handleTabChange}
                      indicatorColor="primary"
                      textColor="inherit"
                      sx={{
                        "& .MuiTab-root": {
                          color: "#b5b5c3",
                          "&.Mui-selected": {
                            color: "#ffffff",
                          },
                        },
                        "& .MuiTabs-indicator": {
                          backgroundColor: "#6366f1",
                        },
                      }}
                    >
                      <Tab label="All Leagues" />
                      <Tab label="Most Members" />
                      <Tab label="Most Questions" />
                    </Tabs>
                  </Box>
                </Box>
              </Box>

              <Divider sx={{ mb: 3 }} />

              {errorMessage ? (
                <Typography color="error">{errorMessage}</Typography>
              ) : (
                <Box sx={{ overflowX: "auto" }}>
                  <CustomMUITable
                    TABLE_HEAD={TABLE_HEAD}
                    data={filteredLeagues.map((league) => ({
                      ...league,
                      members: renderMembersColumn(league),
                      status: league.isLoading ? (
                        <CircularProgress size={20} />
                      ) : null,
                    }))}
                    MENU_OPTIONS={MENU_OPTIONS}
                  />
                </Box>
              )}

              {filteredLeagues.length === 0 && !isLoading && !errorMessage && (
                <Box sx={{ textAlign: "center", py: 4 }}>
                  <Typography variant="h6" color="text.secondary">
                    No leagues found
                  </Typography>
                  <Typography color="text.secondary">
                    Try adjusting your search or filters
                  </Typography>
                  <Button
                    variant="outlined"
                    sx={{ mt: 2 }}
                    onClick={() => {
                      setSearchQuery("");
                      setFilterSubject("all");
                      setTabValue(0);
                    }}
                  >
                    Clear Filters
                  </Button>
                </Box>
              )}

              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2" color="text.secondary">
                  Showing {filteredLeagues.length} of {leaguesList.length}{" "}
                  leagues
                </Typography>
              </Box>
            </Paper>
          </div>
        </div>
      </div>

      {/* Create League Dialog */}
      <Dialog
        open={openCreateDialog}
        onClose={handleCloseCreateDialog}
        maxWidth="sm"
        fullWidth
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#1e1e2d", // Darker background
            color: "#ffffff", // White text
          },
          "& .MuiInputLabel-root": {
            color: "#b5b5c3", // Lighter label color
          },
          "& .MuiOutlinedInput-root": {
            color: "#ffffff", // White text in inputs
            "& fieldset": {
              borderColor: "#323248", // Border color
            },
            "&:hover fieldset": {
              borderColor: "#6366f1", // Hover border color
            },
          },
          "& .MuiSelect-icon": {
            color: "#b5b5c3", // Dropdown icon color
          },
          "& .MuiMenuItem-root": {
            color: "#ffffff", // Menu item text color
          },
        }}
      >
        <DialogTitle>Create New League</DialogTitle>
        <DialogContent className="input-form" sx={{ color: "white" }}>
          <TextField
            autoFocus
            name="name"
            label="League Name"
            type="text"
            fullWidth
            variant="outlined"
            value={newLeague.name}
            onChange={handleLeagueInputChange}
            className="mt-4 form-control"
          />
          <TextField
            name="description"
            label="Description"
            type="text"
            fullWidth
            multiline
            rows={3}
            variant="outlined"
            value={newLeague.description}
            onChange={handleLeagueInputChange}
            className="mt-4 form-control"
          />
          <FormControl fullWidth className="mt-4 form-control">
            <InputLabel>Category</InputLabel>
            <Select
              value={newLeague.category}
              onChange={handleLeagueInputChange}
              name="category"
              label="Category"
            >
              <MenuItem value="PPL">PPL</MenuItem>
              <MenuItem value="CPL">CPL</MenuItem>
              <MenuItem value="ATPL">ATPL</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined" className="mt-4 form-control">
            <InputLabel>Subject</InputLabel>
            <Select
              value={newLeague.subject}
              onChange={handleLeagueInputChange}
              placeholder="Select Subject"
              fullWidth
              variant="outlined"
              className="mui-select"
              name="subject"
              label="Subject"
            >
              {subjects.map((subject) => (
                <MenuItem key={subject.value} value={subject.value}>
                  {subject.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            name="numberOfQuestions"
            label="Number of Questions"
            type="number"
            fullWidth
            variant="outlined"
            value={newLeague.numberOfQuestions}
            onChange={handleLeagueInputChange}
            InputProps={{ inputProps: { min: 1, max: 50 } }}
            className="mt-4 form-control"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreateDialog}>Cancel</Button>
          <Button
            onClick={handleCreateLeague}
            variant="contained"
            color="primary"
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>

      {/* Invite Members Dialog */}
      <Dialog
        open={openInviteDialog}
        onClose={handleCloseInviteDialog}
        maxWidth="md"
        fullWidth
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#1e1e2d", // Darker background
            color: "#ffffff", // White text
          },
          "& .MuiInputLabel-root": {
            color: "#b5b5c3", // Lighter label color
          },
          "& .MuiOutlinedInput-root": {
            color: "#ffffff", // White text in inputs
            "& fieldset": {
              borderColor: "#323248", // Border color
            },
          },
          "& .MuiListItemText-primary": {
            color: "#ffffff", // Primary text in list items
          },
          "& .MuiListItemText-secondary": {
            color: "#b5b5c3", // Secondary text in list items
          },
          "& .MuiCheckbox-root": {
            color: "#6366f1", // Checkbox color
          },
        }}
      >
        <DialogTitle>Invite Members to League</DialogTitle>
        <DialogContent className="input-form" sx={{ color: "white" }}>
          {inviteLeague && (
            <Typography variant="subtitle1" gutterBottom>
              League:{" "}
              <strong>
                {inviteLeague.name ||
                  `${inviteLeague.category} - ${inviteLeague.subject} - ${inviteLeague.topic}`}
              </strong>
              {inviteLeague.reference_code && (
                <Chip
                  label={`Ref: ${inviteLeague.reference_code}`}
                  size="small"
                  color="primary"
                  sx={{ ml: 1 }}
                />
              )}
            </Typography>
          )}

          <TextField
            fullWidth
            placeholder="Search members by name or email..."
            variant="outlined"
            size="small"
            value={searchMemberQuery}
            onChange={(e) => setSearchMemberQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ mb: 2, mt: 2 }}
          />

          <FormControl fullWidth margin="dense">
            <InputLabel id="passengers-select-label">
              Select Passengers
            </InputLabel>
            <Select
              labelId="passengers-select-label"
              id="passengers-select"
              multiple
              value={selectedPassengers}
              onChange={handlePassengerSelection}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((id) => {
                    const passenger = passengersList.find((p) => p._id === id);
                    return passenger ? (
                      <Chip
                        key={id}
                        label={`${passenger.first_name} ${passenger.last_name}`}
                        size="small"
                      />
                    ) : null;
                  })}
                </Box>
              )}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              }}
            >
              {filteredPassengers.map((passenger) => (
                <MenuItem key={passenger._id} value={passenger._id}>
                  <Checkbox
                    checked={selectedPassengers.includes(passenger._id)}
                  />
                  <ListItemText
                    primary={`${passenger.first_name} ${passenger.last_name}`}
                    secondary={passenger.email}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {filteredPassengers.length === 0 && (
            <Typography
              color="text.secondary"
              sx={{ mt: 2, textAlign: "center" }}
            >
              No members found matching your search criteria
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseInviteDialog}>Cancel</Button>
          <Button
            onClick={handleAddMembers}
            variant="contained"
            color="primary"
            disabled={selectedPassengers.length === 0}
          >
            Add {selectedPassengers.length} Members
          </Button>
        </DialogActions>
      </Dialog>

      {/* League Details Drawer */}
      <CustomDrawer
        isOpenDrawer={isDrawerOpen}
        onCloseDrawer={() => setIsDrawerOpen(false)}
        componentToPassDown={<LeagueDetailsDrawer />}
        pageTitle="League Details"
      />

      {/* Confirmation Dialog */}
      <ConfirmationPopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title="Are you sure you want to delete this league?"
        handleAgree={handleDelete}
      />
    </>
  );
}

export default LeaguesList;
